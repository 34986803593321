import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import xss from "xss"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"

const Home = () => {
  const [madLibsOptions, setMadLibsOptions] = useState([])
  const [madLibsWords, setMadLibsWords] = useState({
    // title: null,
    // title2: null,
    adjective: null,
    verb: null,
    noun: null,
    // directive: null,
    // activity: null,
    animal: null,
    phrase: null,
  })
  const [userInputs, setUserInputs] = useState({
    // userTitle: "",
    // userTitle2: "",
    userAdjective: "",
    userVerb: "",
    userNoun: "",
    // userDirective: "",
    // userActivity: "",
    userAnimal: "",
    userPhrase: "",
  })

  const data = useStaticQuery(graphql`
    {
      options: allContentfulMadLib {
        nodes {
          id
          madlibTitle
          title
          title2
          adjective
          verb
          noun
          directive
          activity
          animal
          phrase
        }
      }
    }
  `)
  const { options = [] } = data

  useEffect(() => {
    setMadLibsOptions(options.nodes)
  }, [data])

  return (
    <Layout>
      <SEO>
        <meta charSet="utf-8" />
        <title>Evelyn Crowley</title>
        <meta
          name="description"
          content="Evelyn Crowley is a content director and creative strategist. She is also the founder of The Caret."
        />
      </SEO>
      <main className="mad-libs">
        <section className="mad-libs-form">
          <h1>
            Hi! I’m Evelyn. I’m a creative strategist based in Brooklyn.&nbsp;I help brands craft their stories and become &nbsp;
            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"adjective"}
                id={"adjective"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userAdjective: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.adjective ? xss(madLibsWords.adjective) : ""}
              </div>
              <div className="input-label">(adjective)</div>
            </div>{" "}
            storytellers in their own right.

{/*            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"title2"}
                id={"title2"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userTitle2: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.title2 ? xss(madLibsWords.title2) : ""}
              </div>
              <div className="input-label">(Title)</div>
            </div>
*/}

            &nbsp; I have two decades of experience working with clients including Netflix, Spotify, Vogue, Sonos, and Fenty.&nbsp; I &nbsp;
            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"noun"}
                id={"noun"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userVerb: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.verb ? xss(madLibsWords.verb) : ""}
              </div>
              <div className="input-label">(verb)</div>
            </div>
            &nbsp;brand positioning, verbal identity, and full-stack content strategy and execution.&nbsp; I've created chart-topping apps, viral social campaigns, Webby-winning newsletters, Fortune 500 company voice guides, celebrity podcasts, and a best-selling moisturizer (or, at least, I named it). &nbsp;

            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"phrase"}
                id={"phrase"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userPhrase: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.phrase ? xss(madLibsWords.phrase) : ""}
              </div>
              <div className="input-label">(phrase)</div>
            </div>

            &nbsp;I hang out with my&nbsp;
            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"noun"}
                id={"noun"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userAnimal: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.animal ? xss(madLibsWords.animal) : ""}
              </div>
              <div className="input-label">(animal)</div>
            </div>
            &nbsp;Hedda, and write a weekly newsletter on internet culture called <a href="https://www.surfthiscity.com/" target="_blank" rel="noreferrer noopener">Surf City</a>, which is read by execs at The New York Times, The Atlantic, and The Guardian.&nbsp;

            {/* <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"noun"}
                id={"noun"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userNoun: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.noun ? xss(madLibsWords.noun) : ""}
              </div>
              <div className="input-label">(verb)</div>
            </div> */}
            &nbsp;I like my eggs over easy, my fonts sans serif, and my&nbsp;
            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"directive"}
                id={"directive"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    usernoun: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.noun ? xss(madLibsWords.noun) : ""}
              </div>
              <div className="input-label">(noun)</div>
            </div>
            &nbsp; ample.
          </h1>

          {/* <h2 className="fun-facts">
           The personal stuff: I live in Brooklyn, New York, with my 
           <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"animal"}
                id={"animal"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userAnimal: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.animal ? xss(madLibsWords.animal) : ""}
              </div>
              <div className="input-label">(Animal)</div>
            </div>

            , Hedda. I like my eggs over easy, my fonts sans serif, and my&nbsp;
 
            <div className="input">
              <div
                contentEditable={true}
                suppressContentEditableWarning={true}
                type="text"
                name={"activity"}
                id={"activity"}
                className="mad-libs-input"
                onInput={e => {
                  setUserInputs({
                    ...userInputs,
                    userActivity: xss(e.target.innerText),
                  })
                }}
              >
                {madLibsWords.activity ? xss(madLibsWords.activity) : ""}
              </div>
              <div className="input-label">(noun)</div>
            </div>

            &nbsp; ample.

          </h2> */}
        </section>
        <Footer
          options={madLibsOptions}
          setMadLibsWords={setMadLibsWords}
          home={true}
          userInputEmail={{
            ready:
              // userInputs.userTitle?.length > 0 ||
              // userInputs.userTitle2?.length > 0 ||
              userInputs.userAdjective.length > 0 ||
              userInputs.userVerb.length > 0 ||
              userInputs.userNoun.length > 0 ||
              // userInputs.userDirective?.length > 0 ||
              // userInputs.userActivity?.length > 0 ||
              userInputs.userAnimal.length > 0 ||
              userInputs.userPhrase.length > 0,
            body: xss(
              `Evelyn Crowley is a ${
                userInputs.userTitle ? userInputs.userTitle : "BLANK"
              } and ${
                userInputs.userTitle2 ? userInputs.userTitle2 : "BLANK"
              }. She likes to make smart, ${
                userInputs.userAdjective ? userInputs.userAdjective : "BLANK"
              } things for the internet and real life. She also likes to think about how companies can ${
                userInputs.userVerb ? userInputs.userVerb : "BLANK"
              } content to connect and resonate with their audience. Evelyn started her career in editorial, where she learned the value of storytelling and the difference between a colon and semi-colon. She eventually made her way into marketing and advertising, inspired by the role that brands have come to play in our lives and their stake in shaping today’s ${
                userInputs.userNoun ? userInputs.userNoun : "BLANK"
              }. Evelyn has worked with publications (Vogue, W), big companies (Spotify, Levis, Revlon), creative agencies (Spring Studios, Imprint Projects) and early-stage ventures (natureofthings, 11 Honore) alike. She is available for brand strategy, content roadmapping and strategy, social media everything, ad campaigns, copywriting and script writing.  ${
                userInputs.userDirective ? userInputs.userDirective : "BLANK"
              }  a list of her work here. \n \n And now, some fun-ish facts: Evelyn is based in LA, where she runs the digital culture site The Caret and enjoys the occasional ${
                userInputs.userActivity ? userInputs.userActivity : "BLANK"
              } with her ${
                userInputs.userAnimal ? userInputs.userAnimal : "BLANK"
              }, Agnes.`
            ),
          }}
        />
      </main>


        <section id="services">
          <header>
            <h2>Services</h2>            
          </header>

          <div id="services-grid">

            <div className="grid-item bg-yellow">
              <h3>Content Strategy</h3>
              <p className="text-lg"><strong>For when:</strong> You need a plan but don’t know where to start, or your existing content is falling flat.</p>
              <p>I’ll devise a custom, end-to-end strategy, rooted in research and analysis, that covers the channels you should show up on, the content you should be producing, and the roadmap to make it all happen.</p>
            </div>

            <div className="grid-item bg-orange">
              <h3>Content Director for Hire</h3>
              <p className="text-lg"><strong>For when:</strong> You're ready to get things up and running.</p>
              <p>I’ll work with your team to put your content strategy into action, recruiting and hiring talent, creating a seamless production process, ideating and executing concepts, setting KPIs, and tracking performance metrics.</p>
            </div>

            <div className="grid-item bg-blue">
              <h3>Newsletter Consulting</h3>
              <p className="text-lg"><strong>For when:</strong> You want to launch a newsletter or need help growing subscribers for an existing one.</p>
              <p>If you’re starting from scratch, I’ll create a winning strategy that includes platform recommendation, concept, voice, and content types. If your goal is optimization, I’ll conduct an in-depth performance audit and devise a go-forward plan based on both my findings and extensive knowledge of the landscape.</p>
            </div>

            <div className="grid-item bg-purple">
              <h3>Brand Strategy</h3>
              <p className="text-lg"><strong>For when:</strong> You want to stand out in your category and win your customers.</p>
              <p>I’ll lead a multi-step process that includes extensive research and custom workshops to help you arrive at a strategic positioning that feels authentic to your brand, unique to the market, and resonates with your customer.</p>
            </div>

            <div className="grid-item bg-green">
              <h3>Verbal Identity</h3>
              <p className="text-lg"><strong>For when:</strong> You need a compelling voice and guidelines to implement it across your brand ecosystem.</p>
              <p>I’ll shape your brand’s voice and tone and map out rules for usage to ensure you speak clearly and consistently wherever you show up.</p>
            </div>

            <div className="grid-item">
              <h3>Not sure what you need?</h3>
              <p className="text-lg">Don't sweat it. Schedule <a href="https://calendly.com/crowley-evvie/30min" target="_blank">a call</a> with me and we'll figure it out together.</p>
            </div>


          </div>

        </section>


        <section>

        <div id="stats-grid">

          <div className="grid-item">
            <h3>15<sup>Years</sup></h3>
            <p>Experience</p>
          </div>          

          <div className="grid-item">
            <h3>25<sup>+</sup></h3>
            <p>Brands</p>
          </div>          

          <div className="grid-item">
            <h3>2</h3>
            <p>Worn out laptops</p>
          </div>          

          <div className="grid-item">
            <h3>1</h3>
            <p>Excruciating papercut</p>
          </div>          


        </div>

        </section>

      <Footer home={false} />

    </Layout>
  )
}
export default Home
